@import url('https://fonts.googleapis.com/css2?family=Inter:wght@200;300;400;500;600;700&display=swap');
@tailwind base;
@tailwind components;
@tailwind utilities;
* {
    box-sizing: border-box;
    padding: 0;
    margin: 0;
}
body {
    background-color: rgb(244, 246, 246);
    min-height: 100vh;
}
